<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-primary">
                    <!-- /.card-header -->
                    <!-- form start -->
            <form role="form" @submit.prevent="submitForm">
                <div class="invoice p-3 mb-3">
                    <div class="row">
                        <div class="card card-default">
                            <!-- <form role="form" @submit="submitForm"> -->
                            <div class="card-header">
                                <h3 class="card-title"></h3>

                                <div class="card-tools">
                                    <button
                                    type="button"
                                    class="btn btn-tool"
                                    data-card-widget="collapse"
                                    >
                                    <i class="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- /.card-header -->
                            <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label class="control-label">Mapel</label>
                                <v-select :options="optMapel" v-model="form.mapel_id" :reduce="opt => opt.id" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Judul Materi</label>
                                <input id="title" class="form-control" v-model="form.title" type="text" name="title" />
                            </div>
                            <div class="col-md-12">
                            <label class="control-label">Isi Materi</label>
                            <vue-editor v-model="form.content" :editor-toolbar="customToolbar" />
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="submit" class="btn btn-primary mr-1">Simpan</button>
                    <router-link
                    to="/mapel-materi"
                    class="btn btn-secondary"
                    >
                    Kembali
                    </router-link>
                </div>
            </form>
        </div>
    </div>
</div>
    </section>
</template>
<script>
import Vue from "vue";
import { authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import { VueEditor } from "vue2-editor";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

export default {
    data() {
        return {
            errors: [],
            optMapel: [],
            roles: "",
            method: "POST",
            form: {
                content: "<h1>Ketik materi di sini..</h1>",
                mapel_id: "",
                title: "",
            },
            customToolbar: [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ header: 1 }, { header: 2 }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ color: [] }, { background: [] }],
                ["link", "image", "video"],
                [{ direction: "rtl" }],
            ],
        };
    },
    components: { 
        VueEditor,
        vSelect,
    },
    created: function() {
        // alert(this.$route.params.id);
        if (this.$route.params.id) {
            this.loadMateri(this.$route.params.id);
            this.method = "PUT";
        }

        authFetch('/akademik/data_guru/mapel')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMapel = js.data;
            })
    },
    methods: {
        loadMateri(id) {
            authFetch("/akademik/mapel_materi/edit_materi/" + id).then((res) => {
                res.json().then((json) => {
                    if (json) {
                        this.form = json;
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                    }
                });
            });
        },
        submitForm: function (ev) {
            const e = this.$refs;
            var data = Object.keys(this.form)
                .map(
                (key) =>
                    encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
                )
                .join("&");
            var urlSubmit = "/akademik/mapel_materi";
            if (this.method == "PUT")
                urlSubmit = "/akademik/mapel_materi/" + this.form.id;

            Swal.fire({
                title: "Simpan?",
                showCancelButton: true,
                confirmButtonText: `Ya`,
                cancelButtonText: "Tidak",
            }).then((result) => {
                if (result.isConfirmed) {
                authFetch(urlSubmit, {
                    method: this.method,
                    body: data,
                })
                    .then((res) => {
                    return res.json();
                    })
                    .then((js) => {
                    if (js.success) {
                        Swal.fire("Proses Berhasil", ``, "success");
                        this.form = {};
                        this.$router.push('/mapel-materi');
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                        }
                    });
                }
            });
            ev.preventDefault();
        },
    },
    mounted() {
        const e = this.$refs;
        const self = this;
    },
}
</script>